import { makeStyles, styled } from '@material-ui/styles'
import {
  borders,
  compose,
  display,
  flexbox,
  palette,
  positions,
  shadows,
  sizing,
  spacing,
  typography,
} from '@material-ui/system'
import renderBloks from '@renderBloks'
import { ContentContainer, PageContainer } from '@system'
import camelCase from 'lodash/camelCase'
import React from 'react'
import SbEditable from 'storyblok-react'

const formatProps = (props) => {
  const spacing = [
    'border',
    'borderTop',
    'borderRight',
    'borderBottom',
    'borderLeft',
    'flexGrow',
    'flexShrink',
    'm',
    'mb',
    'ml',
    'mr',
    'mt',
    'mx',
    'my',
    'order',
    'p',
    'pl',
    'pr',
    'pt',
    'px',
    'py',
    'zIndex',
  ]

  let newProps = { ...props }

  spacing.forEach((key) => {
    if (props[key]) {
      newProps[key] = parseInt(props[key], 10)
    }
  })

  newProps.bgColor = props.bgColor.color
  newProps.bgImage = props.bgImage.filename
  newProps.borderColor = props.borderColor.color
  newProps.color = props.color.color || 'inherit'
  newProps.component = props.htmlTag

  return newProps
}

const structureCSSEntries = (entries) =>
  entries?.reduce((css, property) => {
    if (property.key) {
      const propertyKey = camelCase(property.key.replace(':', ''))
      return {
        ...css,
        [propertyKey]: `${property.value.replace(';', '')} !important`,
      }
    }

    return { ...css }
  }, {})

const StyledDiv = styled('div')(
  compose(
    borders,
    display,
    flexbox,
    palette,
    positions,
    shadows,
    sizing,
    spacing,
    typography
  )
)

const useStyles = makeStyles((theme) => ({
  boxRoot: ({
    bgBlendMode,
    bgClip,
    bgColor,
    bgImage,
    bgOrigin,
    bgPosition,
    bgRepeat,
    bgSize,
    css,
    mobileCSS,
    tabletCSS,
  }) => ({
    backgroundBlendMode: bgBlendMode || null,
    backgroundClip: bgClip || null,
    backgroundColor: bgColor || null,
    backgroundImage: bgImage ? `url(${bgImage})` : null,
    backgroundOrigin: bgOrigin || null,
    backgroundPosition: bgPosition || null,
    backgroundRepeat: bgRepeat || null,
    backgroundSize: bgSize || null,
    ...structureCSSEntries(css),
    [theme.breakpoints.down('sm')]: structureCSSEntries(tabletCSS),
    [theme.breakpoints.down('xs')]: structureCSSEntries(mobileCSS),
  }),
}))

const Box = ({ blok }) => {
  const formattedProps = formatProps(blok)
  const {
    _editable,
    _uid,
    bgBlendMode,
    bgClip,
    bgColor,
    bgImage,
    bgOrigin,
    bgPosition,
    bgRepeat,
    bgSize,
    content,
    css,
    children,
    clone,
    mobileCSS,
    tabletCSS,
    ...composableProps
  } = formattedProps

  const classes = useStyles({
    bgBlendMode,
    bgClip,
    bgColor,
    bgImage,
    bgOrigin,
    bgPosition,
    bgRepeat,
    bgSize,
    css,
    mobileCSS,
    tabletCSS,
  })

  const filteredProps = Object.fromEntries(
    Object.entries(composableProps).filter(([_key, val]) => val !== '')
  )

  return (
    <SbEditable content={blok}>
      <PageContainer parentWidth={!blok.hasPageContainer}>
        <ContentContainer parentWidth={!blok.hasFixedContainer}>
          <StyledDiv
            className={classes.boxRoot}
            clone={clone}
            {...filteredProps}
          >
            {renderBloks(formattedProps.content)}
          </StyledDiv>
        </ContentContainer>
      </PageContainer>
    </SbEditable>
  )
}

export default Box
